import React, { memo } from 'react';
import { Backdrop, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ApontamentosOPTable = memo(function ApontamentosTable({ data, isLoading }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Colaborador</TableCell>
            <TableCell>Projeto</TableCell>
            <TableCell>OP</TableCell>
            <TableCell>Serviço</TableCell>
            <TableCell>Criado</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Início</TableCell>
            <TableCell>Fim</TableCell>
            <TableCell>HT</TableCell>
            <TableCell>Comentários</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <CircularProgress color="warning" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && data.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.emp}
              </TableCell>
              <TableCell>{item.pr}</TableCell>
              <TableCell>{item.op}</TableCell>
              <TableCell>{item.srv}</TableCell>
              <TableCell>{item.created}</TableCell>
              <TableCell>{item.datetime}</TableCell>
              <TableCell>{item.startTime}</TableCell>
              <TableCell>{item.endTime}</TableCell>
              <TableCell>{item.qtd}</TableCell>
              <TableCell>{String(item.obs ?? '').slice(0, 50)}</TableCell>
            </TableRow>
          ))}
          {!isLoading && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align="center">
                Nenhum apontamento encontrado
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ApontamentosOPTable;
