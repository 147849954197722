import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import dataGridText from "assets/dataGridText";

export default function StepMap({ onChange, options, value }) {

  const [stages, setStages] = useState({});

  const [data, setData] = useState([]);

  useEffect(() => {
    const stages = {};
    options.map((opt, i) => {
      if (opt.StageType == 3) {
        stages[opt.StageID] = {
          label: `${opt.StageID} - ${opt.Description}`,
          value: opt.StageID,
        };
      }
    });
    setStages(stages);
  }, [options]);

  const handleChangeData = (newData, oldData) => {
    const stageID = newData.StageID;
    const index = data.findIndex((row) => row.id === oldData.id);
    if (index >= 0 && oldData.status !== true && stageID) {
      const updatedData = [...data];
      const stageIndex = Object.keys(stages).indexOf(String(stageID));
      const stage = options[stageIndex];
      updatedData[index] = {
        ...data[index],
        StageID: stage.StageID,
        LineID: stage.LineID,
        U_EASY_TempoPlanejado: newData.U_EASY_TempoPlanejado,
        ExpectedCosts: newData.ExpectedCosts,
      };
      onChange(updatedData);
    }
    return oldData;
  }

  const columns = [
    { field: 'StageID', headerName: 'ID', width: 300, editable: true, type: 'singleSelect', valueOptions: Object.values(stages) },
    { field: 'Description', headerName: 'Descrição', width: 200, editable: false, },
    { field: 'U_EASY_TempoPlanejado', headerName: 'Horas', width: 200, type: 'number', editable: false, },
    { field: 'ExpectedCosts', headerName: 'Custo', width: 200, type: 'number', editable: false, },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      width: 300,
      renderCell: (params) => {
        if (params.row.status === true) {
          return <Alert severity="success" variant="outlined">Salvo</Alert>;
        }
        else if (params.row.status) {
          return <Alert severity="error" variant="filled" style={{ overflow: 'auto' }}>Erro: {params.row.status}</Alert>;
        }
        return null;
      },
    }
  ];

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <Grid
      container direction="column" justifyContent="center" alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} style={{ width: '100%', overflow: 'auto' }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                stage: false,
                saved: false,
              },
            },
          }}
          rows={data}
          processRowUpdate={handleChangeData}
          onProcessRowUpdateError={(error) => console.error(error)}
          columns={columns}
          isCellEditable={(params) => !params.row.saved}
          isRowSelectable={() => false}
          editMode="row"
          style={{ height: '50vh' }}
          localeText={dataGridText}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}

StepMap.defaultProps = {
  options: [],
};
