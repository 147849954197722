import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

const header = [
  'StageType',
  'Task',
  'StageName',
  'Description',
  'StartDate',
  'UniqueID',
  'ExpectedCosts',
  'CostsWithTaxes',
  'U_EASY_TempoPlanejado',
];

const cabecalho = [
  'STAGETYPE',
  'TASK',
  'StageName',
  'DESCRIÇÃO',
  'StartDate',
  'UniqueID',
  'Preço Líquido',
  'Preço com impostos',
  'Quantidade',
];

const sheetName = 'IMPORT_SAP';

export async function importFromTemplate(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => importCallback(e, resolve, reject);
    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

async function importCallback(e, resolve, reject) {
  const data = new Uint8Array(e.target.result);
  const workbook = XLSX.read(data, { type: 'array', cellDates: true });
  if (!checkWorkbook(workbook)) {
    return reject('Planilha inválida! Selecione uma planilha válida.');
  }
  try {
    const data = extractData(workbook);
    console.log(data);
    return resolve(data);
  } catch (error) {
    return reject(error);
  }
}

function checkWorkbook(workbook) {
  const w = workbook.Sheets[sheetName];
  const j = XLSX.utils.sheet_to_json(w, { header: 1 });
  if (j[2].toString() === cabecalho.toString()) {
    return true;
  }
  return false;
}

function extractData(workbook) {
  const sheet = workbook.Sheets[sheetName];
  let rows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
  const data = [];
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i];
    if (row.length === 0 || !row[0]) {
      continue;
    }
    if (row[0] != 3) {
      continue;
    }
    let value = row[6].replace('R$', '');
    value = value.replace(',', '');
    value = value.replace('-', '');
    value = value.trim();
    value = parseFloat(value);
    if (value <= 0 || isNaN(value)) {
      continue;
    }
    row[6] = value;
    value = row[8];
    value = parseFloat(value);
    if (value <= 0 || isNaN(value)) {
      continue;
    }
    row[8] = value;
    const item = {};
    header.forEach((key, index) => {
      item[key] = row[index];
    });
    item['id'] = item['Task'];
    item['LineID'] = null;
    item['StageID'] = '';
    data.push(item);
  }
  return data;
}
