import dayjs from "dayjs";

export const statuses = [
  { id: 'pst_Started', label: 'Iniciado' },
  { id: 'pst_Canceled', label: 'Cancelado' },
  { id: 'pst_Finalized', label: 'Finalizado' },
];

export const types = [
  { id: 'pt_Internal', label: 'Interno' },
  { id: 'pt_External', label: 'Externo' },
];

export const activities = [
  { label: "Organização e Coordenação", id: 32 },
  { label: "Mecânica", id: 33 },
  { label: "Componentes", id: 34 },
  { label: "Elétrica", id: 35 },
  { label: "Barramentos", id: 38 },
  { label: "Materiais para Campo", id: 39 },
  { label: "Frete sobre Compras", id: 40 },
  { label: "Despesas", id: 41 },
  { label: "Serviços Gerais Fábrica", id: 42 },
  { label: "Serviços Gerais Sede", id: 43 },
];

export async function fetchProjects(sessionID, name, FinancialProject, status, type, startDate, endDate) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'AbsEntry,FinancialProject,ProjectName,StartDate,DueDate,ProjectStatus,ProjectType');
  apiUrl.searchParams.append('$orderby', 'AbsEntry desc');

  const filters = [];
  if (name) {
    filters.push(`contains(ProjectName,'${name}')`);
  }
  if (FinancialProject) {
    filters.push(`contains(FinancialProject,'${FinancialProject}')`);
  }
  if (status) {
    filters.push(`ProjectStatus eq '${status}'`);
  }
  if (type) {
    filters.push(`ProjectType eq '${type}'`);
  }
  if (startDate && (!name && !FinancialProject)) {
    filters.push(`StartDate ge '${startDate}'`);
  }
  if (endDate && (!name && !FinancialProject)) {
    filters.push(`StartDate le '${endDate}'`);
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['AbsEntry'];
            item['label'] = item['ProjectName'];
            item['pf'] = item['FinancialProject'];
            item['di'] = dayjs(item['StartDate']).format('DD/MM/YYYY');
            item['df'] = item['DueDate'] ? dayjs(item['DueDate']).format('DD/MM/YYYY') : '-';
            item['type'] = item['ProjectType'] ? types.find(type => type.id === item['ProjectType']).label : '-';
            item['status'] = item['ProjectStatus'] ? statuses.find(status => status.id === item['ProjectStatus'])?.label : '-';
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

async function fetchProjectTotals(sessionID, ProjectID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('sql05')/List?ProjectID=${ProjectID}`);
  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then((json) => {
      const item = json;
      return item['value'];
    });
}

export async function fetchProject(sessionID, ProjectID, getTotals = true) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${ProjectID})`);
  let data = null;

  data = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then((json) => {
      const item = json;
      return item;
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });

  if (data && getTotals) {
    await fetchProjectTotals(sessionID, ProjectID)
      .then((totals) => {
        totals.forEach((total) => {
          data['PM_StagesCollection'].forEach((stage, index) => {
            if (!data['PM_StagesCollection'][index]['TotalInvoice']) {
              data['PM_StagesCollection'][index]['TotalInvoice'] = 0;
            }
            if (!data['PM_StagesCollection'][index]['TotalPurchase']) {
              data['PM_StagesCollection'][index]['TotalPurchase'] = 0;
            }
            if (stage.StageID === total.StageID) {
              if (total['TYP'] === 18) {
                data['PM_StagesCollection'][index]['TotalInvoice'] = total['TotalValue'];
              } else if (total['TYP'] === 22) {
                data['PM_StagesCollection'][index]['TotalPurchase'] = total['TotalValue'];
              }
            }
          });
        });
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }

  return data;
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchPaymentMethods(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/PaymentTermsTypes');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'GroupNumber,PaymentTermsGroupName');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data.push({
              label: `${item['GroupNumber']} - ${item['PaymentTermsGroupName']}`,
              id: item['GroupNumber'],
            });
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function patchProjectManagement(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
}

export async function updateProjectManagement(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
}
