export async function fetchProductTrees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees');
  let data = [];
  let repeat = true;

  const select = [
    'TreeCode',
    'TreeType',
    'Quantity',
    'Project',
    'Warehouse',
    'Project',
    'ProductDescription',
  ];

  apiUrl.searchParams.append('$select', select.join(','));

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['TreeCode'];
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchProductTree(sessionID, id) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees('${id}')`);

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  const data = await response.json();
  return data;
}

export async function fetchWarehouses(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Warehouses');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'WarehouseCode');

  apiUrl.searchParams.append('$filter', "Inactive eq 'tNO' and (BusinessPlaceID eq 11 or BusinessPlaceID eq 3)");

  apiUrl.searchParams.append('$orderby', 'WarehouseCode');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data.push({
              value: item['WarehouseCode'],
              label: item['WarehouseCode'],
            });
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function createProductTree(sessionID, data) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees');

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    throw await response.json();
  }
  return null;
}

export async function updateProductTree(sessionID, code, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees('${code}')`);

  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
  return null;
}

export async function fetchProductTreesCodes(sessionID, codes) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/ProductTrees");

  apiUrl.searchParams.append("$select", "TreeCode");

  const filters = codes.map((code) => `TreeCode eq '${code}'`).join(' or ');

  apiUrl.searchParams.append("$filter", filters);

  let data = [];
  let repeat = true;

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set("$skip", data.length);
    await fetch(apiUrl, {
      method: 'GEt',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          json.value.forEach((item) => {
            data.push(item);
          });
          repeat = true;
        }
      });
  }

  return data;
}
