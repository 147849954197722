import dayjs from "dayjs";

export const statuses = [
  { id: 'pst_Started', label: 'Iniciado' },
  { id: 'pst_Canceled', label: 'Cancelado' },
  { id: 'pst_Finalized', label: 'Finalizado' },
];

export const types = [
  { id: 'pt_Internal', label: 'Interno' },
  { id: 'pt_External', label: 'Externo' },
];

export const activities = [
  { id: 1, label: 'Assistência' },
  { id: 2, label: 'Ausência' },
  { id: 3, label: 'Capacitação/treinamento' },
  { id: 4, label: 'Comissionamento' },
  { id: 5, label: 'COMOS' },
  { id: 6, label: 'Compras' },
  { id: 7, label: 'DDE Automação' },
  { id: 8, label: 'DDE Elétrica' },
  { id: 9, label: 'DDE Instrumentação' },
  { id: 10, label: 'DDE Mecânica' },
  { id: 11, label: 'DDE Modelamento 3D' },
  { id: 12, label: 'DDE Processo' },
  { id: 13, label: 'DDE Tubulação' },
  { id: 14, label: 'Desenvolvimento de tela' },
  { id: 15, label: 'Desenvolvimento software' },
  { id: 16, label: 'Deslocamento' },
  { id: 17, label: 'Diagrama Eletromecânico' },
  { id: 18, label: 'Elaboração de manuais e Relatórios' },
  { id: 19, label: 'Férias' },
  { id: 20, label: 'Folga / Atestado' },
  { id: 21, label: 'Folga de campo' },
  { id: 22, label: 'Gestão' },
  { id: 23, label: 'Montagem' },
  { id: 24, label: 'Operação Assistida' },
  { id: 25, label: 'Reunião Interna' },
  { id: 26, label: 'Reunião Projeto' },
  { id: 27, label: 'Sem alocação em projeto' },
  { id: 28, label: 'Suporte Comercial' },
  { id: 29, label: 'TAF' },
  { id: 30, label: 'Visita comercial' },
  { id: 31, label: 'Visita técnica' }
];

export const locals = ["Acesso Remoto", "Campo", "Escritório", "Fábrica"];

export async function fetchProjects(sessionID, name, FinancialProject, status, type, startDate, endDate) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'AbsEntry,FinancialProject,ProjectName,StartDate,DueDate,ProjectStatus,ProjectType');
  apiUrl.searchParams.append('$orderby', 'AbsEntry desc');

  const filters = [];
  if (name) {
    filters.push(`contains(ProjectName,'${name}')`);
  }
  if (FinancialProject) {
    filters.push(`contains(FinancialProject,'${FinancialProject}')`);
  }
  if (status) {
    filters.push(`ProjectStatus eq '${status}'`);
  }
  if (type) {
    filters.push(`ProjectType eq '${type}'`);
  }
  if (startDate && !name && !FinancialProject) {
    filters.push(`StartDate ge '${startDate}'`);
  }
  if (endDate && !name && !FinancialProject) {
    filters.push(`StartDate le '${endDate}'`);
  }

  if (filters.length > 0) {
    apiUrl.searchParams.append('$filter', filters.join(' and '));
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['AbsEntry'];
            item['label'] = item['ProjectName'];
            item['pf'] = item['FinancialProject'];
            item['di'] = dayjs(item['StartDate']).format('DD/MM/YYYY');
            item['df'] = item['DueDate'] ? dayjs(item['DueDate']).format('DD/MM/YYYY') : '-';
            item['type'] = item['ProjectType'] ? types.find(type => type.id === item['ProjectType']).label : '-';
            item['status'] = item['ProjectStatus'] ? statuses.find(status => status.id === item['ProjectStatus']).label : '-';
            data.push(item);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchProject(sessionID, ProjectID) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${ProjectID})`);

  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then((json) => {
      const item = json;
      return item;
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });
}

export async function fetchTimesheetStagesTotals(sessionID, Project) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('timesheetStagesTotals')/List");

  apiUrl.searchParams.append("project", `'${Project}'`);

  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then((json) => json.value)
    .catch(error => {
      console.error('Error:', error);
      return null;
    });
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function patchProjectManagement(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
}

export async function updateProjectManagement(sessionID, id, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/ProjectManagements(${id})`);

  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 204) {
    throw await response.json();
  }
}
