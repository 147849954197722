import { useContext } from 'react';
import Cookie from 'js-cookie';

import { ConfigContext } from '../context/config';

export const useConfig = () => {
  const { config, setConfig } = useContext(ConfigContext);

  /**
   * Changes specifics properties
   *
   * @param {Object} [change={}] - The properties to be changed
   */
  function changeConfig(change={}) {
    const confs = {
      ...config,
      ...change,
    }
    setConfig(confs);
    Cookie.set('config', JSON.stringify(confs));
  }

  return { config, changeConfig };
};
