import React, { createContext, useState } from 'react';
import Cookie from 'js-cookie';

export const ConfigContext = createContext();

const raw = Cookie.get('config');
let confs = {
  saveFilters: false,
  darkMode: false,
};

if (raw) {
  confs = JSON.parse(raw);
}

export const ConfigProvider = ({ children }) => {

  const [config, setConfig] = useState(confs);

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};
